import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

// pages
import Index from './pages/index';
import Page from './pages/page';

import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyCS3UX3vwiePZc-rE-qzob9LPV7Bo1lM7c",
  authDomain: "adrenaline-coaching.firebaseapp.com",
  projectId: "adrenaline-coaching",
  storageBucket: "adrenaline-coaching.appspot.com",
  messagingSenderId: "633982658699",
  appId: "1:633982658699:web:bc4703429b010fe6f0cc8e",
  measurementId: "G-KVDKVM9S2K"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAnalytics(app);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
    errorElement: <Index />,
  },
  {
    path: '/polo/*',
    element: <Page />,
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
